import React from "react";
import { Link } from "gatsby";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import * as S from "../../styles/modal/styles";

const ModalExamplePage = () => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <S.ModalWrapper>
        {modal ? (
          <Link
            className="closeBtn"
            to={closeTo}
            state={{
              noScroll: false,
            }}
          >
            <p>Fechar</p>
            <S.CloseBlack />
          </Link>
        ) : null}
        <h2>Login</h2>
        <form action="https://nucleolaboratorio.com/validacao.php" method="post" name="formpesquisalaudo">
          <div className="radioType">
            <p>Tipo:</p>
            <label className="radio">
              <input
                type="radio"
                id="cliente"
                name="radiotipolaudo"
                value="cliente"
                className="hidden"
                defaultChecked
              />
              <span className="label"></span>Cliente
            </label>
            <label className="radio">
              <input
                type="radio"
                id="hospital"
                name="radiotipolaudo"
                value="hospital"
                className="hidden"
              />
              <span className="label"></span>Hospital
            </label>
          </div>
          <div className="inputBlock">
            <input type="text" className="inputText" name="codigoprotocolo" placeholder="Código" />
            <input type="password" className="inputText" name="senhaprotocolo" placeholder="Senha" />
          </div>
          <button className="btn">Entrar</button>
        </form>
      </S.ModalWrapper>
    )}
  </ModalRoutingContext.Consumer>
);

export default ModalExamplePage;
