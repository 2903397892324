import styled from "styled-components";

import { Close } from "@styled-icons/material-rounded/Close";

export const ModalWrapper = styled.div`
  background: #fafafa;
  padding: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-image: url(../../img/imgModal.png);
  background-position: right -60% bottom;
  background-repeat: no-repeat;

  .closeBtn {
    display: flex;
    position: absolute;
    right: 34px;
    top: 34px;
    p {
      color: black;
    }
  }
  h2 {
    font-weight: bold;
    font-size: 56px;
    line-height: 68px;
    color: #1c1e21;
    padding-bottom: 45px;
  }
  p {
    font-size: 16px;
    line-height: 19px;
    color: #1c1e21;
    font-weight: 900;
  }
  .radioType {
    display: flex;
    justify-content: center;
    padding-bottom: 28px;
  }
  .inputBlock {
    max-width: 344px;
  }
  .btn{
    margin-top: 30px;
  }
`;

export const CloseBlack = styled(Close)`
  color: black;
  width: 20px;
  height: 20px;
  margin-left: 15px;
`;
